<template>
  <div class="goodNews">
    <Head classA="3" headBg="1"></Head>
    <div class="main">
      <h2>喜报！公司资质再添增项——喜获医疗器械网络经营备案资质！</h2>
      <p class="time">2019-08-01</p>
      <p>
        <span
          >致医健康顺利获得《医疗器械网络交易服务第三方平台备案凭证》，医疗销售交易正式步入合规化进程！2018年3月1日，国家食药监总局颁布《医疗器械网络销售监督管理办法》，规定医疗器械网络交易服务第三方平台提供者应向所在地省级食药监部门备案。</span
        >
      </p>
      <p>
        <span
          >经过历时4个多月的资料准备、提交申请、受理审查、现场检查等一系列环节，2019年5月17日，我司获取北京市药品监督管理局签发的《医疗器械网络交易服务第三方平台备案凭证》(备案编号：（京）网械平台备字（2019）第00005号)；2019年6月21日，我司通过北京市药品认证中心的现场核验，北京市药监局对此结果依法进行了公示。</span
        >
      </p>
      <p>
        <span
          >目前国内获得医疗器械网络交易服务第三方平台备案凭证的企业并不多，此项资质的获得，是公司继国家高新企业证书之后的又一重要增项！此次资质申请工作的顺利完成，不仅使公司完成了医疗器械网络交易的合规化审查，更使公司的企业资质得到了进一步的巩固和提升。新资质的取得，将使公司在相关领域得到进一步扩展，业务范围得到进一步延伸，提高企业核心竞争力，为企业提质增效、快速发展提供了强有力的支撑。</span
        >
      </p>
      <img
        src="../../assets/images/newsDetail/goodNews/yiliao.jpg"
        width="360"
      />
    </div>
    <Foot></Foot>
  </div>
</template>
<style scoped>
@import '../../assets/style/goodNews.css';
</style>
<style scoped>
.goodNews .main p span {
  text-indent: 0 !important;
}
</style>
<script>
import Foot from '@/components/common/Foot'
import Head from '@/components/common/Head'

export default {
  name: 'news',
  components: {
    Head,
    Foot
  },
  data () {
    return {}
  },
  methods: {},
  mounted () {}
}
</script>
